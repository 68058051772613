import { css } from 'aphrodite';
import * as React from 'react';
import { styleSheet } from './styles';

// import './styles.less';

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	className?: string;
	inputRef?: React.MutableRefObject<HTMLTextAreaElement> | React.LegacyRef<HTMLTextAreaElement>;
	inputClassName?: string;
	inputId: string;
	leftAccessory?: React.ReactNode;
	rightAccessory?: React.ReactNode;
}

export const TextArea: React.FC<IProps> = props => {
	const { className, inputClassName, inputId, leftAccessory, rightAccessory, inputRef, id, ...restProps } = props;
	let inRef: HTMLTextAreaElement;
	const onRef = (input?: HTMLTextAreaElement) => {
		inRef = input;

		if (typeof inputRef === 'function') {
			inputRef(input);
		} else if (inputRef != null) {
			(inputRef as React.MutableRefObject<HTMLTextAreaElement>).current = input;
		}
	};

	const onPaddingClicked = (e: React.MouseEvent<HTMLDivElement>) => {
		if (inRef) {
			inRef.focus();
		}

		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<div className={`text-area ${css(styleSheet.container)} ${className || ''}`} id={id} onClick={onPaddingClicked}>
			{leftAccessory}
			<textarea
				{...restProps}
				id={inputId}
				className={`text-area-input ${css(styleSheet.input)} ${inputClassName || ''}`}
				ref={onRef}
			/>
			{rightAccessory}
		</div>
	);
};
